import React from 'react'
import { LanguageCode } from '../../types/LanguageCode'
// @ts-ignore
import { translate } from '../../translate'
import { Maybe } from '../../types/generated'

type LanguageContextData = {
  langcode: LanguageCode
  translationPaths: {
    en?: Maybe<string>
    es?: Maybe<string>
    so?: Maybe<string>
    vi?: Maybe<string>
    fil?: Maybe<string>
  }

  // eslint-disable-next-line react/no-unused-prop-types
  t: (text: string) => string
}

type LanguageContextDefaultData = Omit<LanguageContextData, 't'>

const languageContextDefaultValue = {
  langcode: LanguageCode.English,
  translationPaths: {
    en: '',
    es: '',
    vi: '',
    // so: '',
    // fil: '',
  },

  t: (text: string) => text,
}

export const LanguageContext = React.createContext<LanguageContextData>(languageContextDefaultValue)

type LanguageContextProviderProps = React.PropsWithChildren<LanguageContextDefaultData>

const LanguageContextProvider = ({
  children,
  langcode,
  translationPaths,
}: LanguageContextProviderProps) => (
  <LanguageContext.Provider
    value={{
      langcode,
      translationPaths,
      t: (text: string) => translate(langcode, text),
    }}
  >
    {children}
  </LanguageContext.Provider>
)

export default LanguageContextProvider
