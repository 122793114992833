import React, { useContext } from 'react'
import { Helmet } from 'react-helmet'
import { useLocation } from '@reach/router'
import { useStaticQuery, graphql } from 'gatsby'
import { LanguageContext } from '../../context/LanguageContext'
import { LanguageCode } from '../../types/LanguageCode'
import { Maybe } from '../../types/generated'

interface Props {
  title?: Maybe<string>
  description?: Maybe<string>
  image?: Maybe<string>
  article?: Maybe<string>
  url?: Maybe<string>
}

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        siteUrl
        twitterUsername
        defaultImage
      }
    }
  }
`

const SEO = ({ title, description, image, article, url }: Props) => {
  const { pathname } = useLocation()
  const { site } = useStaticQuery(query)
  const { langcode, translationPaths } = useContext(LanguageContext)

  const {
    defaultTitle,
    titleTemplate,
    defaultDescription,
    siteUrl,
    twitterUsername,
    defaultImage,
  } = site.siteMetadata

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    image: image || defaultImage,
    url: url ? `${siteUrl}${url}` : `${siteUrl}${pathname}`,
  }

  const paths = { ...translationPaths }

  return (
    <Helmet title={seo.title} titleTemplate={titleTemplate}>
      <html lang={langcode} />
      {Object.keys(paths).map((key) =>
        paths[key as LanguageCode] !== undefined ? (
          <link
            key={key}
            rel="alternate"
            hrefLang={key}
            href={`https://discover.sandiegounified.org${paths[key as LanguageCode]}`}
          />
        ) : null,
      )}
      {translationPaths?.en && (
        <link
          rel="alternate"
          hrefLang="x-default"
          href={`https://discover.sandiegounified.org${translationPaths[LanguageCode.English]}`}
        />
      )}
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />

      {seo.url && <meta property="og:url" content={seo.url} />}
      {(article ? true : null) && <meta property="og:type" content="article" />}
      {seo.title && <meta property="og:title" content={seo.title} />}
      {seo.description && <meta property="og:description" content={seo.description} />}
      {seo.image && <meta property="og:image" content={seo.image} />}
      <meta name="twitter:card" content="summary_large_image" />
      {twitterUsername && <meta name="twitter:creator" content={twitterUsername} />}
      {seo.title && <meta name="twitter:title" content={seo.title} />}
      {seo.description && <meta name="twitter:description" content={seo.description} />}
      {seo.image && <meta name="twitter:image" content={seo.image} />}
    </Helmet>
  )
}

export default SEO
