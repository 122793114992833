// Keep this file .js with ES5 module exports
const PostalCodeGeoCoordinates = require('./data/PostalCodeGeoCoordinates.json')

const HOME_PAGE_REGEX = /^\/#$/
const transformAlias = (alias) => {
  if (HOME_PAGE_REGEX.test(alias)) {
    return '/'
  }

  return alias?.replace(/internal:/, '')
}

const getDefaultDescription = (shcoolTitle = '', lang = '') => {
  if (lang === 'es') {
    return `
      <p> ${shcoolTitle} está orgulloso de nuestros programas de arte y música. Creemos que las artes visuales y escénicas son esenciales para el crecimiento y desarrollo de cada niño. El Distrito Escolar Unificado de San Diego promueve la alfabetización artística, la innovación, la creatividad y la excelencia en las artes al brindar una experiencia artística secuencial y de alta calidad para todos los estudiantes. Nuestros estudiantes han actuado en todas partes, desde el Desfile del Rose Bowl hasta el Carnegie Hall, pero su hijo no tiene que ser una celebridad en el futuro para beneficiarse de aprender a expresarse a través de las artes. </p>

      <p> ${shcoolTitle} cuenta con sólidos académicos y profesores de primer nivel. En todo el Distrito Unificado de San Diego, las escuelas primarias e intermedias han demostrado un progreso académico superior en los últimos años. (Fuente: "Los estudiantes de San Diego se destacan en todo el país con mejoras en los puntajes de las pruebas", por Lauryn Schroeder para Union Tribune, 10 de abril de 2018.)</p>

      <p> ${shcoolTitle} proporciona a todos los estudiantes una educación en liderazgo y responsabilidad cívica. La participación cívica comienza en el aula, donde los niños están expuestos a lecciones de historia y gobierno desde el tercer grado. Las actividades de acción pueden incluir investigar las leyes y las libertades que están diseñadas para proteger, o escribir a los funcionarios electos sobre un tema que apasiona al estudiante.</p>

      <p> ${shcoolTitle} se centra en la salud y el bienestar en todos los grados. Las escuelas del Distrito Escolar Unificado de San Diego han recibido reconocimiento nacional como las escuelas más saludables de Estados Unidos. El premio es otorgado por el Programa de Escuelas Saludables de Alliance for a Healthier Generation, una iniciativa nacional basada en evidencia para crear entornos escolares más saludables donde los niños puedan prosperar. El premio demuestra el compromiso de nuestro distrito de fomentar la salud de nuestros estudiantes y personal. Además, la Asociación Estadounidense del Corazón también honró al Distrito Unificado de San Diego por construir una cultura de salud dentro de nuestro distrito escolar.</p>

      <p> Todos los estudiantes de ${shcoolTitle} tienen acceso a una computadora. El acceso a tecnología avanzada ayuda a presentar y educar a nuestros estudiantes en materias que son cada vez más esenciales para la universidad y las carreras del futuro. Empresas como Google, Qualcomm e Intuit Education se han asociado con el Distrito Unificado de San Diego para ayudar a los estudiantes a colaborar entre sí en línea y aprender a ser mejores solucionadores de problemas. </p>
      `
  }

  if (lang === 'en') {
    return `
      <p>${shcoolTitle} is proud of our arts and music programs. We believe the Visual and Performing Arts are essential to the growth and development of every child. San Diego Unified promotes artistic literacy, innovation, creativity, and excellence in the arts by providing a high quality, sequential, TK-12 arts experience for all students. Our students have performed everywhere from the Rose Bowl Parade, to Carnegie Hall, but your child does not have to be a future celebrity to benefit from learning to express themselves through the arts.</p>
    
      <p>${shcoolTitle} features strong academics and top-notch teachers. Across San Diego Unified, elementary and middle schools have demonstrated superior academic progress in recent years. (Source: “San Diego students stand out nationwide with test score improvements,” by Lauryn Schroeder for the Union Tribune, April 10, 2018.)</p>
      
      <p>${shcoolTitle} provides all students with an education in leadership and civic responsibility. Civic engagement begins in the classroom, where children are exposed to history and government lessons as early as Grade 3. Action activities may include researching laws and the freedoms they are designed to protect, or writing to elected officials about an issue the student is passionate about.</p>
      
      <p>${shcoolTitle} is focused on health and wellness at every grade. San Diego Unified schools have received national recognition as America’s Healthiest Schools. The award is granted by Alliance for a Healthier Generation’s Healthy Schools Program, an evidence-based national initiative to create healthier school environments where children can thrive. The award demonstrates our district’s commitment to fostering the health of our students and staff. In addition, The American Heart Association also honored San Diego Unified for building a culture of health within our school district.</p>
      
      <p>Every single student at ${shcoolTitle} has computer access. Access to advanced technology helps introduce and educate our students in subjects that are increasingly essential for college and careers of the future. Companies like Google and Qualcomm and Intuit Education have partnered with San Diego Unified to help students collaborate with one another online and learn to become better problem-solvers.</p>
      `
  }

  return ''
}

/**
 * Find the distance between two geo-coordinates.
 *
 * @param {object} coordinateA
 * @param {number} coordinateA.latitude
 * @param {number} coordinateA.longitude
 * @param {object} coordinateB
 * @param {number} coordinateB.latitude
 * @param {number} coordinateB.longitude
 */
const distanceBetweenGeoCoordinates = (coordinateA, coordinateB) => {
  const d2r = (degrees) => degrees * (Math.PI / 180)
  const RADIUS_OF_EARTH_KM = 6371
  const deltaLatitude = d2r(coordinateB.latitude - coordinateA.latitude)
  const deltaLongitude = d2r(coordinateB.longitude - coordinateA.longitude)

  const a =
    Math.sin(deltaLatitude / 2) * Math.sin(deltaLatitude / 2) +
    Math.cos(d2r(coordinateA.latitude)) *
      Math.cos(d2r(coordinateB.latitude)) *
      Math.sin(deltaLongitude / 2) *
      Math.sin(deltaLongitude / 2)

  const ratio = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  const distanceKilometres = RADIUS_OF_EARTH_KM * ratio
  const distanceMiles = distanceKilometres * 0.621371

  return {
    kilometres: distanceKilometres,
    miles: distanceMiles,
  }
}

/**
 * Find the distance between the centre of a postal code and a pair of geo-coordinates.
 *
 * @param {string} postalCode
 * @param {object} coordinates
 * @param {object} coordinates.latitude
 * @param {object} coordinates.longitude
 */
const distanceBetweenPostalCodeAndGeoCoordinates = (postalCode, geoCoordinates) =>
  distanceBetweenGeoCoordinates(PostalCodeGeoCoordinates[postalCode], geoCoordinates)

/**
 * Find postal codes within n miles of the provided geo-coordinates.
 *
 * @param {object} geoCoordinates
 * @param {number} geoCoordinates.latitude
 * @param {number} geoCoordinates.longitude
 * @param {number} miles
 */
const findPostalCodesInRange = (geoCoordinates, miles = 5) => {
  const results = []

  Object.keys(PostalCodeGeoCoordinates).forEach((postalCode) => {
    if (distanceBetweenPostalCodeAndGeoCoordinates(postalCode, geoCoordinates)?.miles < miles) {
      results.push(postalCode)
    }
  })

  return results
}

module.exports = Object.freeze({
  HOME_PAGE_REGEX,
  findPostalCodesInRange,
  transformAlias,
  getDefaultDescription,
})
