// @ts-ignore
import { HOME_PAGE_REGEX, transformAlias } from './utils-node'
import Transformation from './types/Transformation'
import DrupalTransformations from './types/DrupalTransformations'

// re-export node utils as ts
export { HOME_PAGE_REGEX, transformAlias }

export const getWindow = () => {
  if (typeof window !== 'undefined') {
    return window
  }

  return undefined
}

export enum Environment {
  DEVELOPMENT,
  PRODUCTION,
}

export const getEnvironment = () =>
  process.env.GATSBY_CLOUD && process.env.BRANCH === 'master'
    ? Environment.PRODUCTION
    : Environment.DEVELOPMENT

// eslint-disable-next-line arrow-body-style
export const getSeoImage = (image: any) => {
  const seoImage =
    image?.[0]?.relationships?.field_media_image?.uri?.value.replace(
      'public://',
      '/sites/default/files/',
    ) ??
    image?.relationships?.field_media_image?.uri?.value?.replace(
      'public://',
      '/sites/default/files/',
    ) ??
    image?.relationships?.field_image?.relationships?.field_media_image?.uri?.value?.replace(
      'public://',
      '/sites/default/files/',
    )
  return seoImage ? `https://discover-admin.sandiegounified.org${seoImage}` : null
}

const makeBase64UrlSafe = (b64: string) =>
  b64.replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '')

export const urlSafeBase64 = (str: string) => {
  const window = getWindow()

  if (!str) {
    return ''
  }

  if (window) {
    return makeBase64UrlSafe(window.btoa(str))
  }

  if (typeof Buffer !== undefined) {
    return makeBase64UrlSafe(Buffer.from(str, 'ascii').toString('base64'))
  }

  throw new Error('Could not find an environment compatible way to base64 string.')
}

const transformWithDrupal = (
  baseUrl: string,
  publicUri: string,
  transformations: DrupalTransformations,
) => `${baseUrl}/image-transformation-api/${urlSafeBase64(publicUri)}/${transformations.toString()}`

export const getPublicImageTransformationUrl = (
  publicUri: string,
  transformations: Transformation[],
) => {
  if (!publicUri) {
    return ''
  }

  const ORIGIN = 'https://discover-admin.sandiegounified.org'

  // We can't transform SVG
  if (publicUri.endsWith('.svg')) {
    return `${ORIGIN}${publicUri.replace('public://', '/sites/default/files/')}`
  }

  transformations.push({
    operation: 'convert',
    parameters: {
      extension: 'jpeg',
    },
  })

  return transformWithDrupal(ORIGIN, publicUri, new DrupalTransformations(transformations))
}
