import React, { ReactNode } from 'react'
import './styles.css'

interface Props {
  children: ReactNode
}

const ContentRender = ({ children }: Props) => (
  <div className="content-render prose">{children}</div>
)

export default ContentRender
