import React, { useContext } from 'react'
import { LanguageContext } from '../../context/LanguageContext'
import CustomLink from '../CustomLink'
import { FooterMenuFragment, SocialMenuFragment } from '../../types/generated'

interface Props {
  menu: FooterMenuFragment['footerMenu']['nodes']
  socialMenu: SocialMenuFragment['socialMenu']['nodes']
}

const Footer = ({ menu: footerMenu, socialMenu }: Props) => {
  const { t } = useContext(LanguageContext)

  return (
    <footer className="bg-gray-800" aria-labelledby="footer-heading">
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        <div className="pb-8 grid md:grid-cols-2 gap-8 lg:grid-cols-3 xl:grid-cols-5">
          {footerMenu.map((option) => (
            <div key={option.title}>
              <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                {option.title}
              </h3>
              <ul className="mt-4 space-y-4">
                {option.childMenuLinks?.map((subOption) => (
                  <li key={subOption?.title}>
                    <CustomLink
                      to={subOption?.pageReference?.path ?? '/'}
                      className="text-gray-400 border-gray-400 focus:text-white focus:border-white active:text-white active:border-white"
                    >
                      {subOption?.title}
                    </CustomLink>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
        <div className="mt-8 border-t border-gray-700 pt-8 md:flex md:items-center md:justify-between">
          <div className="flex space-x-6 md:order-2">
            {socialMenu.map((option) => (
              <a
                key={option.title}
                href={option?.link?.uri ?? '/'}
                className="text-gray-400 hover:text-gray-300"
              >
                <span className="sr-only">{option.title}</span>
                <img
                  className="w-6 h-6"
                  src={`https://discover-admin.sandiegounified.org${option?.relationships?.field_icon?.relationships?.field_media_image?.uri?.url}`}
                  alt={option.title ?? ''}
                  loading="lazy"
                />
              </a>
            ))}
          </div>
          <p className="mt-8 text-base text-gray-400 md:mt-0 md:order-1">
            &copy; {new Date().getFullYear()} San Diego Unified School District.{' '}
            {t('All rights reserved.')}
          </p>
        </div>
      </div>
    </footer>
  )
}

export default Footer
