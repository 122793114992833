import React, { ReactNode, useState } from 'react'
import ChevronDownIcon from '@heroicons/react/outline/ChevronDownIcon'
import ChevronUpIcon from '@heroicons/react/outline/ChevronUpIcon'
import { HeaderMenuFragment } from '../../types/generated'
import CustomLink from '../CustomLink'
import RenderIf from '../RenderIf'

interface AccordionMenuOptionProps {
  option: ArrayElement<HeaderMenuFragment['headerMenu']['nodes']>
  children: ReactNode
}

const AccordionMenuOption = ({ option, children }: AccordionMenuOptionProps) => {
  const [expanded, setExpanded] = useState(false)
  return (
    <div>
      <button
        type="button"
        className="p-3 w-full flex items-center focus-border-bottom rounded-lg hover:bg-gray-50 cursor-pointer"
        onClick={() => setExpanded(!expanded)}
      >
        {children}
        <RenderIf condition={expanded}>
          <ChevronUpIcon className="w-4 ml-2 text-sdusd-blue" />
        </RenderIf>
        <RenderIf condition={!expanded}>
          <ChevronDownIcon className="w-4 ml-2 text-sdusd-blue" />
        </RenderIf>
      </button>
      <RenderIf condition={expanded && (option?.childMenuLinks?.length ?? 0) > 0}>
        <div className="mt-2 pl-4">
          {option.childMenuLinks?.map((innerOption) => (
            <CustomLink
              key={innerOption?.title}
              to={innerOption?.pageReference?.path ?? '/'}
              className="p-3 w-full focus-border-bottom  flex items-center rounded-lg hover:bg-gray-50 cursor-pointer group"
            >
              <div className="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md bg-sdusd-purple text-white">
                <RenderIf
                  // eslint-disable-next-line
                  condition={
                    !!innerOption?.relationships?.field_icon?.relationships?.field_media_image?.uri
                      ?.url
                  }
                >
                  <img
                    className="p-2"
                    src={`https://discover-admin.sandiegounified.org${innerOption?.relationships?.field_icon?.relationships?.field_media_image?.uri?.url}`}
                    alt={innerOption?.title ?? ''}
                    loading="lazy"
                  />
                </RenderIf>
              </div>
              <span className="ml-2 text-sdusd-blue border-transparent group-hover:border-sdusd-blue border-b-2">
                {innerOption?.title ?? ''}
              </span>
            </CustomLink>
          ))}
        </div>
      </RenderIf>
    </div>
  )
}

type ArrayElement<ArrayType extends readonly unknown[]> =
  ArrayType extends readonly (infer ElementType)[] ? ElementType : never

interface MobileMenuOptionProps {
  option: ArrayElement<HeaderMenuFragment['headerMenu']['nodes']>
  children: ReactNode
}

const MobileMenuOption = ({ option, children }: MobileMenuOptionProps) =>
  option.childMenuLinks && option.childMenuLinks.length > 0 ? (
    <AccordionMenuOption option={option}>{children}</AccordionMenuOption>
  ) : (
    <CustomLink
      to={option?.pageReference?.path ?? option?.link?.uri ?? '/'}
      className="p-3 focus-border-bottom flex items-center rounded-lg hover:bg-gray-50 cursor-pointer group"
    >
      {children}
    </CustomLink>
  )

export default MobileMenuOption
