/* eslint-disable no-console */

const spanish = require('../translations/es.json')
const somali = require('../translations/so.json')
const vietamese = require('../translations/vi.json')
const filipino = require('../translations/fil.json')

const TRANSLATIONS = {
  es: spanish,
  so: somali,
  vi: vietamese,
  fil: filipino,
}

const translate = (langcode, text) => {
  if (langcode === 'en') return text

  // @ts-ignore
  const translationText = TRANSLATIONS[langcode]?.[text]

  if (!translationText) {
    if (process.env.NODE_ENV === 'development') {
      console.warn('Missing translation for interface language', `(${langcode})`, text)
    }

    return text
  }

  return translationText
}

module.exports = Object.freeze({
  translate,
})
