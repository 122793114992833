import React, { ReactNode } from 'react'

interface Props {
  condition: boolean
  children: ReactNode
}

const RenderIf = ({ condition, children }: Props) => {
  if (!condition) return null

  return <>{children}</>
}

export default RenderIf
