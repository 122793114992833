import React, { ReactNode } from 'react'
import { Link } from 'gatsby'

interface Props {
  children: ReactNode
  to: string
  className?: string
}

const CustomLink = ({ children, to, className = '' }: Props) => {
  let linkType = 'external'

  if (/^\/|^internal:/.test(to)) {
    linkType = 'internal'
  }

  if (linkType === 'external') {
    return (
      <a href={to} className={className}>
        {children}
      </a>
    )
  }

  return (
    <Link to={to?.replace('internal:', '')} className={className}>
      {children}
    </Link>
  )
}

export default CustomLink
