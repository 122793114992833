import Transformation from './Transformation'

export default class Transformations {
  transformations: Transformation[]

  toString(): string {
    return this.transformations
      .map(
        (t) =>
          `c_${t.operation},${Object.keys(t.parameters)
            .map((k) => `${k}_${t.parameters[k]}`)
            .join(',')}`,
      )
      .join('|')
  }

  constructor(transformations: Transformation[]) {
    this.transformations = transformations
  }
}
