import React, { Fragment, useContext } from 'react'
import { Popover, Transition } from '@headlessui/react'
import XIcon from '@heroicons/react/outline/XIcon'
import MenuIcon from '@heroicons/react/outline/MenuIcon'
import ChevronDownIcon from '@heroicons/react/solid/ChevronDownIcon'
import { graphql, Link, navigate, useStaticQuery } from 'gatsby'
import CustomLink from '../CustomLink'
import RenderIf from '../RenderIf'

import MobileMenuOption from './MobileMenuOption'
import { LanguageCode } from '../../types/LanguageCode'
import { HeaderMenuFragment } from '../../types/generated'
import { LanguageContext } from '../../context/LanguageContext'
// @ts-ignore
import SDUSDLogo from './logo.png'

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

interface Props {
  menu: HeaderMenuFragment['headerMenu']['nodes']
}

const Header = ({ menu: headerMenu }: Props) => {
  const { langcode, translationPaths } = useContext(LanguageContext)
  const queryResult = useStaticQuery(graphql`
    {
      en: nodePage(drupal_internal__nid: { eq: 34 }, langcode: { eq: "en" }) {
        path {
          alias
        }
      }
      es: nodePage(drupal_internal__nid: { eq: 34 }, langcode: { eq: "es" }) {
        path {
          alias
        }
      }
      fil: nodePage(drupal_internal__nid: { eq: 34 }, langcode: { eq: "fil" }) {
        path {
          alias
        }
      }
      so: nodePage(drupal_internal__nid: { eq: 34 }, langcode: { eq: "so" }) {
        path {
          alias
        }
      }
      vi: nodePage(drupal_internal__nid: { eq: 34 }, langcode: { eq: "vi" }) {
        path {
          alias
        }
      }
    }
  `)

  return (
    <header className="font-display max-w-7xl mx-auto px-4">
      <Popover className="relative bg-white">
        {({ open }) => (
          <>
            <div className="flex justify-between items-center py-6 lg:justify-start lg:space-x-10">
              <div className="align-self-center">
                <Link to={queryResult?.[langcode]?.path?.alias} className="flex">
                  <span className="sr-only">San Diego Unified School District</span>
                  <img
                    width="175"
                    height="40"
                    className="h-10 w-auto"
                    src={SDUSDLogo}
                    alt=""
                    loading="lazy"
                  />
                </Link>
              </div>
              <div className="lg:hidden">
                <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                  <span className="sr-only">Open menu</span>
                  <MenuIcon className="h-6 w-6" aria-hidden="true" />
                </Popover.Button>
              </div>
              <div className="hidden lg:flex-1 lg:flex lg:items-center lg:justify-between">
                <Popover.Group as="nav" className="flex space-x-10">
                  {headerMenu?.map((topOption) => (
                    <>
                      <RenderIf condition={topOption?.childMenuLinks?.length === 0}>
                        <CustomLink
                          to={topOption?.pageReference?.path ?? topOption?.link?.uri ?? '/'}
                          className="text-sdusd-blue border-transparent focus-border-bottom hover:border-sdusd-blue border-b-2 focus:outline-none focus:ring-2 focus:ring-black focus:ring-opacity-50"
                        >
                          <span>{topOption.title}</span>
                        </CustomLink>
                      </RenderIf>
                      <RenderIf condition={topOption?.childMenuLinks?.length !== 0}>
                        <Popover className="relative">
                          {({ open: openInner }) => (
                            <>
                              <Popover.Button
                                className={classNames(
                                  openInner ? 'text-gray-900' : 'text-gray-500',
                                  'group bg-white inline-flex items-center text-base focus-border-bottom hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-black focus:ring-opacity-50',
                                )}
                                onKeyUp={(e: any) => {
                                  if (e.keyCode === 9 && !openInner) e.target.click()
                                }}
                              >
                                <span className="text-sdusd-blue border-transparent hover:border-sdusd-blue border-b-2">
                                  {topOption.title}
                                </span>
                                <ChevronDownIcon
                                  className={classNames(
                                    openInner ? 'text-gray-600' : 'text-gray-400',
                                    'ml-2 h-5 w-5 group-hover:text-gray-500',
                                  )}
                                  aria-hidden="true"
                                />
                              </Popover.Button>

                              <Transition
                                show={openInner}
                                as={Fragment}
                                enter="transition ease-out duration-200"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="transition ease-in duration-150"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                              >
                                <Popover.Panel
                                  static
                                  focus
                                  tabIndex={-1}
                                  className="absolute z-10 mt-3 w-screen max-w-md lg:max-w-3xl"
                                >
                                  <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                                    <div className="relative grid gap-2 bg-white p-2 sm:gap-4 sm:p-4 lg:grid-cols-2 header-link">
                                      {topOption.childMenuLinks?.map((item) => (
                                        <CustomLink
                                          key={item?.title}
                                          to={item?.pageReference?.path ?? '/'}
                                          className="p-3 flex focus-border-bottom items-start rounded-lg hover:bg-gray-50 border-0 group"
                                        >
                                          <div className="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md bg-sdusd-purple text-white sm:h-12 sm:w-12">
                                            <RenderIf
                                              // eslint-disable-next-line
                                              condition={
                                                !!item?.relationships?.field_icon?.relationships
                                                  ?.field_media_image?.uri?.url
                                              }
                                            >
                                              <img
                                                className="p-2"
                                                src={`https://discover-admin.sandiegounified.org${item?.relationships?.field_icon?.relationships?.field_media_image?.uri?.url}`}
                                                alt={item?.title ?? ''}
                                                loading="lazy"
                                              />
                                            </RenderIf>
                                          </div>
                                          <div className="ml-4">
                                            <span className="text-base text-sdusd-blue border-transparent group-hover:border-sdusd-blue border-b-2 focus:outline-none focus:ring-2 focus:ring-black focus:ring-opacity-50">
                                              {item?.title ?? ''}
                                            </span>
                                            <p className="mt-1 text-sm text-gray-500">
                                              {item?.description ?? ''}
                                            </p>
                                          </div>
                                        </CustomLink>
                                      ))}
                                    </div>
                                  </div>
                                </Popover.Panel>
                              </Transition>
                            </>
                          )}
                        </Popover>
                      </RenderIf>
                    </>
                  ))}
                </Popover.Group>
                <div>
                  <form className="sm:max-w-xs">
                    <fieldset className="w-full">
                      <span className="sr-only">Language</span>
                      <div className="relative">
                        <select
                          id="language"
                          name="language"
                          className="appearance-none block w-full bg-none border-sdusd-blue border border-transparent rounded-md py-2 pl-3 pr-10 text-base text-sdusd-blue focus:ring-2 focus:ring-black sm:text-sm bg-transparent"
                          value={langcode}
                          onChange={(e) => {
                            const toLanguage = e.target.value as LanguageCode
                            if (translationPaths?.[toLanguage]) {
                              const to: string = translationPaths[toLanguage] ?? '/'
                              navigate(to)
                            }
                          }}
                        >
                          <option value={LanguageCode.English}>English</option>
                          <option value={LanguageCode.Spanish}>Spanish</option>
                          <option value={LanguageCode.Vietnamese}>Vietnamese</option>
                          {/* <option value={LanguageCode.Filipino}>Tagalog</option>
                          <option value={LanguageCode.Somali}>Somali</option> */}
                        </select>
                        <div className="pointer-events-none absolute inset-y-0 right-0 px-2 flex items-center">
                          <ChevronDownIcon className="h-4 w-4 text-sdusd-blue" aria-hidden="true" />
                        </div>
                      </div>
                    </fieldset>
                  </form>
                </div>
              </div>
            </div>

            {/* Mobile Navbar */}
            <Transition
              show={open}
              as={Fragment}
              enter="duration-200 ease-out"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="duration-100 ease-in"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Popover.Panel
                static
                tabIndex={-1}
                focus
                className="absolute z-50 top-0 inset-x-0 p-2 lg:hidden"
              >
                <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
                  <div className="pt-5 pb-6 px-5">
                    <div className="flex items-center justify-between">
                      <div>
                        <Link to={queryResult[langcode]?.path?.alias}>
                          <img
                            className="h-8 w-auto"
                            src={SDUSDLogo}
                            alt="San Diego Unified School District Logo"
                            loading="lazy"
                          />
                        </Link>
                      </div>
                      <div className="">
                        <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                          <span className="sr-only">Close menu</span>
                          <XIcon className="h-6 w-6" aria-hidden="true" />
                        </Popover.Button>
                      </div>
                    </div>
                    <div className="mt-6">
                      <nav className="grid gap-2">
                        {headerMenu.map((item) => (
                          <MobileMenuOption key={item.title} option={item}>
                            <span className="border-transparent text-sdusd-blue group-hover:border-sdusd-blue border-b-2">
                              {item.title}
                            </span>
                          </MobileMenuOption>
                        ))}
                      </nav>
                    </div>
                  </div>
                  <div className="pb-6 px-5">
                    <div>
                      <fieldset className="w-full">
                        <span className="sr-only">Language</span>
                        <div className="relative">
                          <select
                            id="language"
                            name="language"
                            className="appearance-none block w-full bg-none border-sdusd-blue border border-transparent rounded-md py-2 pl-3 pr-10 text-base text-sdusd-blue focus:ring-2  focus:ring-black sm:text-sm"
                            value={langcode}
                            onChange={(e) => {
                              const toLanguage = e.target.value as LanguageCode
                              if (translationPaths?.[toLanguage]) {
                                const to: string = translationPaths[toLanguage] ?? '/'
                                navigate(to)
                              }
                            }}
                          >
                            <option value={LanguageCode.English}>English</option>
                            <option value={LanguageCode.Spanish}>Spanish</option>
                            <option value={LanguageCode.Vietnamese}>Vietnamese</option>
                            {/* <option value={LanguageCode.Filipino}>Tagalog</option>
                            <option value={LanguageCode.Somali}>Somali</option> */}
                          </select>
                          <div className="pointer-events-none absolute inset-y-0 right-0 px-2 flex items-center">
                            <ChevronDownIcon
                              className="h-4 w-4 text-sdusd-blue"
                              aria-hidden="true"
                            />
                          </div>
                        </div>
                      </fieldset>
                    </div>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </header>
  )
}

export default Header
