import React from 'react'
import Header from '../Header'
import Footer from '../Footer'
import LanguageContextProvider from '../../context/LanguageContext'
import './styles.css'
import { LanguageCode } from '../../types/LanguageCode'
import { PageMenus } from '../../types/PageMenus'
import { Maybe } from '../../types/generated'

type LayoutProps = React.PropsWithChildren<{
  menu: PageMenus
}>

const Layout = ({ children, menu }: LayoutProps) => (
  <div className="font-body">
    <Header menu={menu?.header} />
    <main className="max-w-7xl mx-auto px-4 min-h-screen">{children}</main>
    <Footer menu={menu?.footer} socialMenu={menu?.social} />
  </div>
)

interface Props {
  langcode: LanguageCode
  translationPaths: {
    en?: Maybe<string>
    es?: Maybe<string>
    so?: Maybe<string>
    vi?: Maybe<string>
    fil?: Maybe<string>
  }
  menu: PageMenus
}

type LayoutWrappedProps = React.PropsWithChildren<Props>

const LayoutWrapped = ({ langcode, translationPaths, ...rest }: LayoutWrappedProps) => (
  <LanguageContextProvider translationPaths={translationPaths} langcode={langcode}>
    <Layout {...rest} />
  </LanguageContextProvider>
)

export default LayoutWrapped
